import Layout from '../components/Layout'
import React from 'react'
import SectionConcert from '../components/SectionConcert'
import Seo from '../components/Seo'
import fr from '../locales/fr'
import { graphql } from 'gatsby'

interface ConcertsPageProps {
  data: GatsbyTypes.ConcertsPageQuery
}

const ConcertsPage: React.FunctionComponent<ConcertsPageProps> = ({ data }) => (
  <Layout>
    <Seo title={fr.concerts} description={fr.concertsMetaDesc} />
    <SectionConcert concerts={data.concerts.nodes} />
  </Layout>
)

export default ConcertsPage

export const pageQuery = graphql`
  query ConcertsPage {
    concerts: allWpConcert(sort: { order: DESC, fields: acf___date }) {
      nodes {
        ...Concert
      }
    }
  }
`
